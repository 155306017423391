
import { defineComponent } from 'vue';

export default defineComponent({
  inheritAttrs: false,
  emits: ['input', 'blur', 'focus'],
  props: {
    modelValue: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return { isInputActive: false };
  },
  computed: {
    displayValue: {
      get: function () {
        if (this.isInputActive) {
          return this.modelValue?.toString();
        } else {
          return `NGN ${this.modelValue
            ?.toFixed(2)
            .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')}`;
        }
      },
      set: function (modifiedValue: any) {
        let newValue = parseFloat(modifiedValue.replace(/[^\d.]/g, ''));
        if (isNaN(newValue)) {
          newValue = 0;
        }
        this.$emit('update:modelValue', newValue);
      },
    },
  },
  methods: {
    blur() {
      this.isInputActive = false;
      this.$emit('blur');
    },
    focus() {
      this.isInputActive = true;
      this.$emit('focus');
    },
  },
});
